import request from '@/utils/request.js';
/**
 *获取隐患分页
 * @returns {Promise}
 */
export function getHiddenDangerPage(data) {
  return request({
    url: '/traffic-construction/getHiddenDangerPage',
    method: 'post',
    data
  });
}

/**
 *新增隐患
 * @returns {Promise}
 */
export function addDanger(data) {
  return request({
    url: '/traffic-construction/addDanger',
    method: 'post',
    data
  });
}

/**
 * 修改隐患(除id外其他参数同新增隐患)
 * @returns {Promise}
 */
export function updateHiddenDanger(data) {
  return request({
    url: '/traffic-construction/updateHiddenDanger',
    method: 'post',
    data
  });
}

/**
 * 删除隐患
 * @returns {Promise}
 */
export function deleteHiddenDanger(data) {
  return request({
    url: '/traffic-construction/deleteHiddenDanger',
    method: 'post',
    data
  });
}

/**
 * 添加整改或者复核记录
 * @returns {Promise}
 */
export function addRectificationRecord(data) {
  return request({
    url: '/traffic-construction/addRectificationRecord',
    method: 'post',
    data
  });
}

/**
 * 获取隐患图表数据
 * @returns {Promise}
 */
export function getHiddenDangerPhoto(data) {
  return request({
    url: '/traffic-construction/getHiddenDangerPhoto',
    method: 'post',
    data
  });
}

/**
 * 导出-整改通知书
 * @returns {Promise}
 */
export function exportRectificationNotice(data) {
  return request({
    url: '/traffic-construction/exportRectificationNotice',
    method: 'post',
    data,
    responseType: 'blob'
  });
}

/**
 * 导出-整改回执书
 * @returns {Promise}
 */
export function exportRectificationReceipt(data) {
  return request({
    url: '/traffic-construction/exportRectificationReceipt',
    method: 'post',
    data,
    responseType: 'blob'
  });
}

/**
 * 导出-闭合文件
 * @returns {Promise}
 */
export function exportHiddenDanger(data) {
  return request({
    url: '/traffic-construction/exportHiddenDanger',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
/**
 * 导出-整改回执书
 * @returns {Promise}
 */
export function hiddenDangerImportData(data) {
  return request({
    url: '/traffic-construction/hiddenDangerImportData',
    method: 'post',
    data
  });
}
/**
 * 根据隐患类型显示隐患类别
 * @returns {Promise}
 */
export function getCategoryByType(params) {
  return request({
    url: '/traffic-construction/configurationDetail/configDetailGetCategoryByTypeIdMapList',
    method: 'get',
    params
  });
}
