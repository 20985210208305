/* 针对于导入模板上传 */
<template>
  <el-upload action="#" :show-file-list="false" :http-request="uploadSectionFile">
    <el-button>导入</el-button>
  </el-upload>
</template>

<script>
import { isFileType } from 'assets/js/uploadFileType';
export default {
  name: 'ImportUpload',
  methods: {
    //覆盖默认的上传行为，可以自定义上传的实现
    uploadSectionFile({ file }) {
      const fileName = file.name;
      //获取文件名后缀名
      const fileSuffix = this.getFileSuffix(fileName);
      if (!isFileType(fileSuffix)) {
        this.$message.error('格式错误');
        return false;
      }
      this.$emit('get-file', file);
    },
    //获取文件后缀名
    getFileSuffix(fileName) {
      var index = fileName.lastIndexOf('.');
      //获取后缀
      var ext = fileName.substr(index + 1);
      return ext;
    }
  }
};
</script>
