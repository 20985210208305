import { configDetailList } from 'api/common';
export const selectList = {
  data() {
    return {
     // projectList: [], //所属项目部
      dangerLevelList: [], //隐患级别
      dangerTypeList: [], //隐患类型
      dangerCategoryList: [] //隐患类别
    };
  },
  methods: {
    configDetailList(boolean = false) {
      //true自定义不加全选，反之
      //获取下拉配置项
      configDetailList({ module: 7 }).then(res => {
        if (res.code === 200) {
          const { seven_category, seven_level, seven_type } = res.data;
          if (boolean) {
            if (seven_level.length) {
              seven_level.unshift({ name: '全选', id: '' });
            }
          }
          if (boolean) {
            if (seven_type.length) {
              seven_type.unshift({ name: '全选', id: '' });
            }
          }
          if (boolean) {
            if (seven_category.length) {
              seven_category.unshift({ name: '全选', id: '' });
            }
          }
          this.dangerLevelList = seven_level;
          this.dangerTypeList = seven_type;
          this.dangerCategoryList = seven_category;
        }
      });
    },
  }
};
